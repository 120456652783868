<template>
  <div>
    <div class="flex items-start space-x-6">
      <aside
        v-if="isAuthenticated && !isShortlistPage"
        class="
          border border-grey-500
          rounded
          max-w-xs
          w-full
          divide-y divide-grey-500
        "
      >
        <accordion-item v-if="showPublishers" class="relative px-4 py-3">
          <template :slot="'title'">
            <span class="text-base text-black-900 font-medium">
              Publishers
            </span>
          </template>
          <template :slot="'content'">
            <checkbox-field
              name="publishers"
              :options="filterParams.publishers"
              :value="filters.publishers"
              @input="
                (event) => {
                  handleFiltersChange('publishers', event);
                }
              "
            />
            <!-- <select-input placeholder="Find Publishers" /> -->
          </template>
        </accordion-item>
        <accordion-item class="relative px-4 py-3">
          <template :slot="'title'">
            <span class="text-base text-black-900 font-medium">
              Date Added
            </span>
          </template>
          <template :slot="'content'">
            <t-datepicker
              :monthsPerView="2"
              :multiple="true"
              :range="true"
              :weekStart="1"
              placeholder="Choose date or period"
              dateFormat="Y-m-d"
              userFormat="d M Y"
              :value="filters.createdDate"
              @input="
                (event) => {
                  if (event && (event.length == 0 || event.length == 2)) {
                    handleFiltersChange('createdDate', event);
                  }
                }
              "
            />
          </template>
        </accordion-item>
        <accordion-item class="relative px-4 py-3">
          <template :slot="'title'">
            <span class="text-base text-black-900 font-medium">
              Date Updated
            </span>
          </template>
          <template :slot="'content'">
            <t-datepicker
              :monthsPerView="2"
              :multiple="true"
              :range="true"
              :weekStart="1"
              placeholder="Choose date or period"
              dateFormat="Y-m-d"
              userFormat="d M Y"
              :value="filters.updatedDate"
              @input="
                (event) => {
                  if (event && (event.length == 0 || event.length == 2)) {
                    handleFiltersChange('updatedDate', event);
                  }
                }
              "
            />
          </template>
        </accordion-item>
      </aside>

      <main class="flex-1">
        <div v-if="isCatalogsLoading">Loading catalogs...</div>
        <div v-else>
          <div v-if="catalogsError">
            {{ catalogsError }}
          </div>
          <div v-else class="grid grid-cols-2 gap-3">
            <card-catalog
              v-for="catalog in catalogs"
              :key="catalog.id"
              :catalog="catalog"
              :isShortlistPage="isShortlistPage"
              @export-to-pdf="exportToPdf"
            ></card-catalog>
          </div>

          <base-button
            v-if="catalogsCount - catalogs.length > 0"
            class="mt-9"
            @click.native="$emit('handleFetchMore')"
          >
            <svg
              class="mr-2"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 1V11M11 6L1 6"
                stroke="currentColor"
                stroke-width="1.6"
                stroke-linecap="round"
              />
            </svg>
            Show more {{ catalogsCount - catalogs.length }}
          </base-button>
        </div>
      </main>
    </div>

    <div class="vue-html2pdf">
      <transition name="transition-anim">
        <section class="pdf-preview" v-if="pdfFile">
          <button @click.self="closePdf()">&times;</button>
          <iframe :src="pdfFile" width="100%" height="100%" />
        </section>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import CardCatalog from "@/components/cards/CardCatalog.vue";
import { CatalogService } from "@/common/api.service";

export default {
  name: "TabCatalogs",
  components: {
    "card-catalog": CardCatalog,
    "base-button": () => import("@/components/utils/Button.vue"),
    "accordion-item": () => import("@/components/utils/AccordionItem.vue"),
    "checkbox-field": () => import("@/components/utils/CheckboxField.vue"),
  },
  props: {
    showPublishers: {
      type: Boolean,
      default: () => true,
    },
    isCatalogsLoading: Boolean,
    catalogs: Array,
    catalogsCount: {
      type: Number,
      default: 0,
    },
    filterParams: {
      type: Object,
    },
    filters: {
      type: Object,
    },
    isShortlistPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pdfFile: null,
    };
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
    catalogsError() {
      if (this.$route.query.q) {
        if (this.$route.query.q.trim().length < 3) {
          return "The search could not be completed because the keywords were too short.";
        }

        if (this.catalogs.length === 0) {
          return "Sorry, No Catalogs were found that matched your search query. Please try searching again.";
        }
      }
      return "";
    },
  },
  methods: {
    handleFiltersChange(key, value) {
      this.$emit("handleFilters", { key, value });
    },
    async exportToPdf(slug) {
      const { data } = await CatalogService.getPdf(slug);
      const blob = new Blob([data], { type: "application/pdf" });
      const blobUrl = URL.createObjectURL(blob);
      this.pdfFile = blobUrl;
    },
    closePdf() {
      URL.revokeObjectURL(this.pdfFile);
      this.pdfFile = null;
    },
  },
};
</script>
