<template>
  <router-link
    :to="{ name: 'catalog', params: { handle: catalog.handle } }"
    v-slot="{ href, navigate }"
    custom
    :style="{ 'background-image': `url(${require('@/assets/book-bg.svg')})` }"
  >
    <a
      class="
        grid grid-cols-card
        gap-x-6
        rounded
        bg-grey-400
        px-5
        pt-5
        pb-3
        bg-catalog
        cursor-pointer
        hover:shadow-sm
        bg-no-repeat bg-right-bottom
      "
      :href="href"
      @click="navigate"
      @keypress.enter="navigate"
      role="link"
    >
      <img
        :src="catalog.imageUrl"
        :alt="catalog.name"
        class="shadow-md mb-3 h-auto"
        width="134"
      />
      <div class="mb-3">
        <h3 class="text-base text-black-900 font-medium mb-2">
          {{ catalog.name }}
        </h3>
        <h4 class="text-black-900 text-sm mb-2">
          {{ catalog.publisherName }}
        </h4>
        <span
          class="
            flex
            items-center
            text-grey-700 text-sm
            uppercase
            font-medium
            mb-3
          "
        >
          <svg
            class="mr-1.5"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.996 3.32949L11.839 2.47131C11.8175 2.35356 11.715 2.26812 11.5955 2.26812C11.595 2.26812 11.5947 2.26812 11.5942 2.26812C11.4741 2.26862 11.372 2.35517 11.3514 2.47341L9.93904 10.6108L2.74085 10.611C2.56837 10.611 2.40877 10.5397 2.29101 10.4103C2.15605 10.2616 2.08089 10.0398 2.09897 9.84978C2.15172 9.39178 2.48281 9.0592 2.88571 9.0592H8.8929C9.01362 9.0592 9.11688 8.97215 9.13694 8.85292L10.5433 0.538748C10.5554 0.46681 10.5353 0.393262 10.4882 0.337543C10.4412 0.281825 10.372 0.249756 10.2992 0.249756H4.29675C2.92744 0.249756 1.5907 1.36338 1.31669 2.73133L0.0341729 9.01103C-0.081226 9.74627 0.0978156 10.4374 0.538362 10.9576C0.970984 11.4683 1.60531 11.7497 2.32457 11.7497L2.43922 11.7493C2.44145 11.7493 2.44356 11.7494 2.44603 11.7494C2.82913 11.7498 10.1556 11.7503 10.232 11.7503C10.5092 11.7503 10.7455 11.5517 10.7945 11.2727L11.9972 3.41122C12.0015 3.38422 12.0009 3.35636 11.996 3.32949Z"
              fill="currentColor"
            />
          </svg>

          {{ catalog.bookCount }} titles
        </span>
        <p class="text-sm text-black-600">
          {{ catalog.description }}
        </p>
      </div>
      <div class="flex items-center space-x-5">
        <export-pdf-button
          v-if="
            isAuthenticated &&
            (user.type === 'admin' || user.type === 'reseller')
          "
          @export-to-pdf="exportToPdf(catalog.id)"
        />
        <button-bookmark
          v-if="isAuthenticated && !isShortlistPage"
          :added-to-bookmark="catalog.shortlistIds.length > 0"
          @bookmark-click="handleBookmark"
        />
      </div>
      <div class="flex">
        <div class="flex-1">
          <h5 class="text-grey-700 text-sm">Added:</h5>
          <p class="text-black-600 text-sm">{{ catalog.createdAt }}</p>
        </div>
        <div class="flex-1">
          <h5 class="text-grey-700 text-sm">Updated:</h5>
          <p class="text-black-600 text-sm">{{ catalog.updatedAt }}</p>
        </div>
      </div>
    </a>
  </router-link>
</template>
<script>
import { mapGetters } from "vuex";

import ExportPdfButton from "@/components/buttons/ButtonExportPdf.vue";
import ButtonBookmark from "@/components/buttons/ButtonBookmark.vue";
import { LOAD_SELECTED_SHORTLIST } from "@/store/actions.type";

export default {
  name: "CardCatalog",
  components: {
    ExportPdfButton,
    ButtonBookmark,
  },
  props: {
    catalog: {
      type: Object,
      required: true,
    },
    isShortlistPage: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async handleBookmark() {
      if (this.isAuthenticated) {
        await this.$store.dispatch(LOAD_SELECTED_SHORTLIST, {
          shortlistIds: [...this.catalog.shortlistIds],
          bookId: null,
          catalogId: this.catalog.id,
        });
        this.$modal.show("shortlist-modal", {
          component: "add-shortlist-modal",
        });
      } else {
        // redirect to login page if not yet authenticated
        this.$router.push({
          name: "login",
          query: { redirect: this.$route.path },
        });
      }
    },
    exportToPdf(slug) {
      this.$emit("export-to-pdf", slug);
    },
  },
  computed: {
    ...mapGetters(["isAuthenticated", "user"]),
  },
};
</script>
