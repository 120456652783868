<template>
  <div class="mt-4 bg-white-900 mb-14">
    <div class="container mx-auto">
      <actions-bar
        title="Catalogs"
        title-class="text-5xl"
        placeholder="Search by name"
        sortByType="catalogs"
        :sortByValue="sortBy"
        :searchValue="searchValue"
        :hasSortByRelevance="hasSortByRelevance"
        @handleSortByChange="handleSortByChange"
        @handleSearchValueChange="searchValue = $event"
        @handleSearch="handleSearch"
        @handleSearchClear="handleSearchClear"
      />

      <list-catalogs
        class="mb-13"
        :catalogs="catalogs"
        :catalogsCount="catalogsCount"
        :isCatalogsLoading="isCatalogsLoading"
        :filterParams="catalogsFilterParams"
        :filters="filters"
        @handleFilters="handleFilters"
        @handleFetchMore="handleFetchMore"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ActionsBar from "@/components/base/ActionsBar.vue";
import TabCatalogs from "@/components/tabs/Catalogs.vue";
import {
  FETCH_CATALOGS,
  FETCH_MORE_CATALOGS,
  FETCH_CATALOGS_FILTER_PARAMS,
} from "@/store/actions.type";
import {
  RELEVANCE_SORT_BY,
  DEFAULT_CATALOGS_SORT_BY,
} from "@/common/constants";

export default {
  components: {
    "actions-bar": ActionsBar,
    "list-catalogs": TabCatalogs,
  },
  mounted() {
    this.fetchCatalogs();
    if (this.isAuthenticated) {
      this.fetchFilterParams();
    }
  },
  data() {
    let filters = {
      publishers: [],
      createdDate: [],
      updatedDate: [],
    };

    if (this.$route.query.fp) {
      filters.publishers = JSON.parse(decodeURIComponent(this.$route.query.fp));
    }

    if (this.$route.query.fcd) {
      filters.createdDate = JSON.parse(
        decodeURIComponent(this.$route.query.fcd)
      );
    }

    if (this.$route.query.fud) {
      filters.updatedDate = JSON.parse(
        decodeURIComponent(this.$route.query.fud)
      );
    }

    return {
      searchValue: this.$route.query.q ? this.$route.query.q : "",
      sortBy: this.$route.query.s
        ? this.$route.query.s
        : this.$route.query.q
        ? RELEVANCE_SORT_BY
        : DEFAULT_CATALOGS_SORT_BY,
      filters,
      page: 1,
    };
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "isCatalogsLoading",
      "catalogs",
      "catalogsCount",
      "catalogsFilterParams",
    ]),
    hasSortByRelevance() {
      return this.$route.query.q && this.$route.query.q.length > 0;
    },
  },
  methods: {
    getQueryParams() {
      return { q: this.searchValue, s: this.sortBy, f: this.filters };
    },
    updateQueryParams(isUpdateQuery) {
      let query = {};

      const q = isUpdateQuery ? this.searchValue : this.$route.query.q;
      if (q && q.length > 0) {
        query["q"] = q;
      }

      if (this.sortBy.length > 0) {
        query["s"] = this.sortBy;
      }

      if (this.filters.publishers.length > 0) {
        query["fp"] = encodeURIComponent(
          JSON.stringify(this.filters.publishers)
        );
      }

      if (this.filters.createdDate.length > 0) {
        query["fcd"] = encodeURIComponent(
          JSON.stringify(this.filters.createdDate)
        );
      }

      if (this.filters.updatedDate.length > 0) {
        query["fud"] = encodeURIComponent(
          JSON.stringify(this.filters.updatedDate)
        );
      }

      this.$router.push({
        name: "catalogs",
        query,
      });
    },
    fetchCatalogs() {
      this.$store.dispatch(FETCH_CATALOGS, this.getQueryParams());
    },
    fetchFilterParams() {
      this.$store.dispatch(FETCH_CATALOGS_FILTER_PARAMS, {
        q: this.searchValue,
      });
    },
    resetFilters() {
      this.filters = {
        publishers: [],
        createdDate: [],
        updatedDate: [],
      };
    },
    handleSortByChange(event) {
      this.sortBy = event;
      this.updateQueryParams(false);
    },
    handleSearch() {
      if (this.searchValue.length > 0) {
        this.sortBy = RELEVANCE_SORT_BY;
      } else {
        this.sortBy = DEFAULT_CATALOGS_SORT_BY;
      }
      this.updateQueryParams(true);
    },
    handleSearchClear() {
      this.searchValue = "";
      this.sortBy = DEFAULT_CATALOGS_SORT_BY;
      this.updateQueryParams(true);
    },
    handleFilters({ key, value }) {
      this.filters = {
        ...this.filters,
        [key]: value,
      };
      this.updateQueryParams(false);
    },
    handleFetchMore() {
      this.page++;
      this.$store.dispatch(FETCH_MORE_CATALOGS, {
        ...this.getQueryParams(),
        p: this.page,
      });
    },
  },
};
</script>
