<template>
  <t-dropdown variant="tooltip" toggle-on-hover>
    <slot slot="trigger"></slot>
    <template> {{ tooltipText }} </template>
  </t-dropdown>
</template>

<script>
export default {
  name: "ButtonTooltip",
  props: {
    tooltipText: {
      type: String,
      required: true,
    },
  },
};
</script>
