<template>
  <button-tooltip tooltip-text="Add to shortlist">
    <button @click.stop.prevent="$emit('bookmark-click')">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :class="[
          addedToBookmark
            ? 'text-orange-900'
            : 'text-black-600 hover:text-grey-700',
        ]"
      >
        <path
          v-if="!addedToBookmark"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.5523 9.5043L12 4.3331L9.44772 9.5043L3.74112 10.3336L7.87038 14.3588L6.89574 20.0425L12.0001 17.3589L17.1041 20.0424L16.1292 14.3588L20.2588 10.3336L14.5523 9.5043ZM1.43235 10.1777L6.25868 14.8825L5.1195 21.5257C5.07891 21.7622 5.17615 22.0011 5.37026 22.1422C5.48007 22.222 5.61013 22.2626 5.74082 22.2626C5.84118 22.2626 5.94187 22.2387 6.03407 22.1902L12 19.0536L17.9658 22.1901C18.1782 22.3018 18.4355 22.2832 18.6296 22.1422C18.8237 22.0011 18.921 21.7621 18.8805 21.5256L17.7409 14.8825L22.5678 10.1777C22.7396 10.0103 22.8015 9.75978 22.7273 9.53159C22.6531 9.30336 22.4559 9.13703 22.2185 9.10257L15.5484 8.13329L12.5653 2.08915C12.4591 1.87399 12.24 1.73779 12.0001 1.73779C11.7601 1.73779 11.541 1.87399 11.4348 2.08915L8.45165 8.13329L1.78165 9.10257C1.54426 9.13703 1.34695 9.30336 1.27283 9.53154C1.19865 9.75978 1.26051 10.0103 1.43235 10.1777Z"
          fill="currentColor"
        />
        <path
          v-else
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.43235 10.1777L6.25868 14.8825L5.1195 21.5257C5.07891 21.7622 5.17615 22.0011 5.37026 22.1422C5.48007 22.222 5.61013 22.2626 5.74082 22.2626C5.84118 22.2626 5.94187 22.2387 6.03407 22.1902L12 19.0536L17.9658 22.1901C18.1782 22.3018 18.4355 22.2832 18.6296 22.1422C18.8237 22.0011 18.921 21.7621 18.8805 21.5256L17.7409 14.8825L22.5678 10.1777C22.7396 10.0103 22.8015 9.75978 22.7273 9.53159C22.6531 9.30336 22.4559 9.13703 22.2185 9.10257L15.5484 8.13329L12.5653 2.08915C12.4591 1.87399 12.24 1.73779 12.0001 1.73779C11.7601 1.73779 11.541 1.87399 11.4348 2.08915L8.45165 8.13329L1.78165 9.10257C1.54426 9.13703 1.34695 9.30336 1.27283 9.53154C1.19865 9.75978 1.26051 10.0103 1.43235 10.1777Z"
          fill="currentColor"
        />
      </svg>
    </button>
  </button-tooltip>
</template>

<script>
import ButtonTooltip from "@/components/utils/ButtonTooltip.vue";

export default {
  name: "ButtonBookmark",
  components: {
    ButtonTooltip,
  },
  props: {
    addedToBookmark: {
      type: Boolean,
      default: () => false,
    },
  },
};
</script>
