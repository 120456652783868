var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"flex items-start space-x-6"},[(_vm.isAuthenticated && !_vm.isShortlistPage)?_c('aside',{staticClass:"\n        border border-grey-500\n        rounded\n        max-w-xs\n        w-full\n        divide-y divide-grey-500\n      "},[(_vm.showPublishers)?_c('accordion-item',{staticClass:"relative px-4 py-3"},[_c('template',{slot:'title'},[_c('span',{staticClass:"text-base text-black-900 font-medium"},[_vm._v(" Publishers ")])]),_c('template',{slot:'content'},[_c('checkbox-field',{attrs:{"name":"publishers","options":_vm.filterParams.publishers,"value":_vm.filters.publishers},on:{"input":function (event) {
                _vm.handleFiltersChange('publishers', event);
              }}})],1)],2):_vm._e(),_c('accordion-item',{staticClass:"relative px-4 py-3"},[_c('template',{slot:'title'},[_c('span',{staticClass:"text-base text-black-900 font-medium"},[_vm._v(" Date Added ")])]),_c('template',{slot:'content'},[_c('t-datepicker',{attrs:{"monthsPerView":2,"multiple":true,"range":true,"weekStart":1,"placeholder":"Choose date or period","dateFormat":"Y-m-d","userFormat":"d M Y","value":_vm.filters.createdDate},on:{"input":function (event) {
                if (event && (event.length == 0 || event.length == 2)) {
                  _vm.handleFiltersChange('createdDate', event);
                }
              }}})],1)],2),_c('accordion-item',{staticClass:"relative px-4 py-3"},[_c('template',{slot:'title'},[_c('span',{staticClass:"text-base text-black-900 font-medium"},[_vm._v(" Date Updated ")])]),_c('template',{slot:'content'},[_c('t-datepicker',{attrs:{"monthsPerView":2,"multiple":true,"range":true,"weekStart":1,"placeholder":"Choose date or period","dateFormat":"Y-m-d","userFormat":"d M Y","value":_vm.filters.updatedDate},on:{"input":function (event) {
                if (event && (event.length == 0 || event.length == 2)) {
                  _vm.handleFiltersChange('updatedDate', event);
                }
              }}})],1)],2)],1):_vm._e(),_c('main',{staticClass:"flex-1"},[(_vm.isCatalogsLoading)?_c('div',[_vm._v("Loading catalogs...")]):_c('div',[(_vm.catalogsError)?_c('div',[_vm._v(" "+_vm._s(_vm.catalogsError)+" ")]):_c('div',{staticClass:"grid grid-cols-2 gap-3"},_vm._l((_vm.catalogs),function(catalog){return _c('card-catalog',{key:catalog.id,attrs:{"catalog":catalog,"isShortlistPage":_vm.isShortlistPage},on:{"export-to-pdf":_vm.exportToPdf}})}),1),(_vm.catalogsCount - _vm.catalogs.length > 0)?_c('base-button',{staticClass:"mt-9",nativeOn:{"click":function($event){return _vm.$emit('handleFetchMore')}}},[_c('svg',{staticClass:"mr-2",attrs:{"width":"12","height":"12","viewBox":"0 0 12 12","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M6 1V11M11 6L1 6","stroke":"currentColor","stroke-width":"1.6","stroke-linecap":"round"}})]),_vm._v(" Show more "+_vm._s(_vm.catalogsCount - _vm.catalogs.length)+" ")]):_vm._e()],1)])]),_c('div',{staticClass:"vue-html2pdf"},[_c('transition',{attrs:{"name":"transition-anim"}},[(_vm.pdfFile)?_c('section',{staticClass:"pdf-preview"},[_c('button',{on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.closePdf()}}},[_vm._v("×")]),_c('iframe',{attrs:{"src":_vm.pdfFile,"width":"100%","height":"100%"}})]):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }